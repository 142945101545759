<template>
  <div class="mb-20 px-4 md:px-16">
    <section class="md:max-w-[1560px] mx-auto">
      <div
        class="bg-white py-10 flex w-full items-center flex-col rounded-lg relative lg:py-12"
      >
        <div
          class="bg-[#45aeb1] shadow-icone rounded-full w-[70px] h-[70px] flex items-center justify-center mb-4 mt-8 lg:w-[135px] lg:h-[135px]"
        >
          <inline-svg
            class="w-6 md:w-10 lg:w-12"
            :src="require('@/assets/novo/prancheta.svg')"
            title="Política De Privacidade"
          />
        </div>
        <div class="px-8 text-[#727272] md:text-lg md:px-20 xl:px-48">
          <h1 class="text-[#003D42] font-bold mb-8 text-center">
            POLÍTICA DE PRIVACIDADE
          </h1>
          <p class="font-bold text-center">
            Aviso de Privacidade – Programa RAStrear® Câncer Colorretal
            Metastático
          </p>
          <p class="mb-2">
            O Programa RAStrear® Câncer Colorretal Metastático é um programa de
            diagnóstico (“Programa”) oferecido pela Interplayers Soluções
            Integradas S/A (“Interplayers”), inscrita no CNPJ
            05.347.060/0006-03, em nome da Amgen Biotecnologia do Brasil Ltda.
            ("Amgen"), inscrita no CNPJ 18.774.815/0001-93.
          </p>
          <p class="mb-2 pl-8">
            Interplayers é a empresa contratada pela Amgen Biotecnologia do
            Brasil para a gestão do Programa (“Operadora de Dados”) é a
            desenvolvedora da plataforma tecnológica que opera o Programa
            Rastrear Câncer Colorretal Metastático, bem como a empresa que
            coleta suas informações pessoais, conforme descrito neste Aviso de
            Privacidade.
          </p>
          <p class="mb-2">
            As informações pessoais são necessárias para participar do Programa
            e incluem as informações pessoais que você fornece voluntariamente
            ao Programa agora ou no futuro, incluindo:
          </p>
          <ul class="list-disc">
            <li class="ml-6 md:ml-12 mb-2">
              Para pacientes: nome completo, CPF, data de nascimento, sexo,
              informações contidas no laudo do seu teste elaborado pelo
              laboratório, inclusive dados de saúde: resultados da análise
              genética (alterações genéticas).
            </li>
            <li class="ml-6 md:ml-12 mb-4">
              Para médicos: seu nome, CPF, número de conselho, telefone e e-mail
            </li>
          </ul>
          <p class="mb-2">
            A Interplayers processará apenas as informações necessárias para:
          </p>
          <p class="mb-2">
            I. inscrevê-lo no Programa e fornecer os serviços para os quais você
            se registrou, que podem incluir:
          </p>
          <ul class="list-disc">
            <li class="ml-6 md:ml-12 mb-2">
              Para paciente: para fornecer a você teste para identificação do
              gene KRAS, NRAS e/ou BRAF e/ou outro suporte, serviços e recursos
              relacionados ao seu diagnóstico que podem ser desenvolvidos no
              futuro;
            </li>
            <li class="ml-6 md:ml-12 mb-2">
              Para médico/enfermeiro: para lhe fornecer acesso a uma plataforma
              online que lhe permitirá monitorar o progresso do seu paciente, se
              este tiver dado o seu consentimento.
            </li>
          </ul>
          <p class="mb-2">
            II. informá-lo sobre andamento dos serviços prestados por este
            programa;
          </p>
          <p class="mb-2">
            III. obter seu feedback sobre sua participação no Programa e avaliar
            o desempenho dos serviços.
          </p>
          <p class="mb-2">
            IV. utilizar seus dados pessoais agregados e anonimizados para
            avaliações e publicações de Evidência de Mundo Real (Real World
            Evidence), quando autorizado por você.
          </p>
          <p class="mb-2">
            Suas Informações Pessoais serão processadas pela Interplayers
            durante sua permanência no Programa e apenas para os propósitos e da
            maneira descrita neste Consentimento. Interplayers mantém medidas de
            segurança técnicas e organizacionais apropriadas para proteger suas
            informações pessoais contra acesso não autorizado ou ilegal, perda
            acidental, alteração ou destruição, de acordo com a lei aplicável.
          </p>
          <p class="mb-2">
            Suas Informações Pessoais serão compartilhadas com o Laboratório
            designado para atender ao hospital onde foi realizada a solicitação
            para a realização do teste e elaboração do laudo, e também por
            terceiros contratados para operacionalizar o oferecimento dos
            serviços de teste e controle de qualidade do mesmo, incluindo a
            logística.
          </p>
          <p class="mb-2">
            Suas Informações Pessoais podem ser combinadas com as informações de
            outras pessoas que participam do Programa para gerar dados agregados
            que não contenham informações de identificação (“Dados Agregados”).
            Os Dados Agregados podem ser utilizados pela Amgen e seus provedores
            de serviços para melhorar e/ou refinar o Programa, para projetar e
            implementar outros programas de pacientes e para fins de pesquisa,
            incluindo a identificação de tendências ou resultados.
          </p>
          <p class="mb-2">
            Em relação à Transferência Internacional de Informações Pessoais,
            seus dados poderão ser compartilhados com nossos parceiros
            localizados no Brasil, na União Europeia e nos Estados Unidos
            (inclusive o laboratório e terceiros contratados para
            operacionalizar o oferecimento dos serviços de teste e controle de
            qualidade do mesmo, incluindo a logística). Suas informações podem,
            por exemplo, ser coletadas no Brasil, transferidas ou transmitidas,
            armazenadas e processadas nos Estados Unidos ou União Europeia.
            Essas transferências internacionais de informações pessoais serão
            realizadas apenas quando necessárias para adequada prestação dos
            serviços de testes. Já para fins de relatos de farmacovigilância
            (segurança), suas informações podem ser transferidas para a Amgen
            Inc. (nos Estados Unidos da América) e outros processadores
            confiáveis agindo em nome da Amgen, localizados em países fora
            daquele em que você reside. As transferências de informações
            pessoais entre a Amgen e as entidades do grupo seguem as leis
            aplicáveis e nossas Regras Corporativas Vinculativas (BCRs). Para
            obter informações sobre os BCRs, visite
            <a
              class="text-[#45aeb1] hover:underline"
              href="http://www.amgen.com/bcr/"
              rel="noopener noreferrer"
              target="_blank"
              >http://www.amgen.com/bcr/</a
            >. As transferências para fornecedores que processam informações
            pessoais sob as instruções da Amgen são feitas usando Contratos
            Modelo (Cláusulas Contratuais Padrão). Independentemente de onde
            suas informações pessoais são coletadas, a Amgen mantém salvaguardas
            apropriadas para garantir um nível adequado de proteção de suas
            informações.
          </p>
          <p class="mb-2">
            A participação no Programa é voluntária e gratuita. A decisão de não
            participar, não afetará os cuidados médicos que o paciente recebe de
            seu médico. No entanto, se você decidir por não concordar com o
            processamento de suas informações pessoais conforme descrito neste
            Aviso de Privacidade, ou optar por não participar dos serviços
            disponíveis do Programa posteriormente, você não poderá participar
            ou receber mais assistência no Programa.
          </p>
          <p class="mb-2">
            Você pode retirar seu consentimento para participar do Programa, a
            qualquer momento, sem dar motivos, notificando Interplayers usando
            os detalhes de contato fornecidos abaixo.
          </p>
          <p class="mb-2">
            No caso do Programa terminar ou você retirar voluntariamente seu
            consentimento para participar do Programa, suas informações pessoais
            serão destruídas com segurança, de acordo com a Política de Retenção
            de Dados da Amgen, em até 15 anos. Em alguns casos, Interplayers
            e/ou Amgen podem ser obrigados pela lei aplicável a reter suas
            informações pessoais além desse período de tempo.
          </p>
          <p class="mb-2">
            Se você deseja solicitar acesso, correção, atualização ou uma cópia
            de suas informações pessoais ou alterar/cancelar sua assinatura do
            Programa, entre em contato com Azimute através de qualquer um dos
            seguintes canais:
          </p>
          <ul class="list-disc">
            <li class="ml-6 md:ml-12 mb-2">
              Online: Envie um e-mail para
              <a
                class="text-[#45aeb1] hover:underline"
                rel="noopener noreferrer"
                target="_blank"
                href="mailto:contato@programarastrear.com.br"
                >contato@programarastrear.com.br</a
              >
            </li>
            <li class="ml-6 md:ml-12 mb-4">
              Telefone: Entre em contato com a central de atendimento 0800 940
              0113
            </li>
          </ul>
          <p class="mb-6">
            Suas informações pessoais não serão utilizadas para nenhum outro
            propósito além dos propósitos descritos acima neste Aviso de
            Privacidade. Caso deseje registrar uma reclamação sobre o uso de
            suas informações pessoais pelo Programa, entre em contato com Amgen
            (<a
              class="text-[#45aeb1] hover:underline"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.amgen.com.br/privacy-statement#"
              >https://www.amgen.com.br/privacy-statement#</a
            >) e/ou Agência Nacional de Proteção de Dados - “ANPD”.
          </p>
        </div>
        <div class="pt-4 pb-8 -mb-1">
          <small class="block text-center mt-2 text-[#727272]">
            SC-BRA-AMG 210-00093. Aprovado em julho/2023.
          </small>
        </div>
      </div>
    </section>
    <section class="mb-14 -mt-4 z-20 relative md:-mt-7 lg:-mt-8">
      <BigButton
        color="blue"
        class="flex justify-center w-full px-6 md:max-w-[432px] md:mx-auto"
      />
    </section>
  </div>
</template>

<script>
import BigButton from "@/components/molecules/BigButton.vue";

export default {
  name: "PoliticaDePrivacidadeColorretal",
  components: { BigButton },
};
</script>
